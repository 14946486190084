<template>
  <div class="calendar-table-date-panel">
    <button
      v-if="!dateItem.disabledFlg"
      class="calendar-date-panel-button"
      :class="{
        selected: dateItem.selectedFlg,
        reserved: dateItem.reservedFlg
      }"
      data-test="date-panel"
      @click="handleClick"
      data-e2e="calendar-table-date-panel"
    >
      <div class="calendar-date-panel-day">{{ dateItem.date | getDay }}</div>
      <div
        v-if="dateItem.reservedFlg"
        class="calendar-date-panel-text reserved"
      >
        {{ $t('parts.reserved') }}
      </div>
      <div v-else class="calendar-date-panel-circle-mark">○</div>
    </button>
    <button
      v-if="dateItem.disabledFlg"
      class="calendar-date-panel-button"
      disabled
    >
      <div class="calendar-date-panel-day">{{ dateItem.date | getDay }}</div>
      <div
        v-if="dateItem.closedDayFlg"
        class="calendar-date-panel-text closed-day"
      >
        {{ $t('parts.closed') }}
      </div>
      <div
        v-else-if="dateItem.crossFlg"
        class="calendar-date-panel-text cross-mark"
      >
        ×
      </div>
    </button>
  </div>
</template>

<script>
export default {
  name: 'CalendarTableDatePanel',

  filters: {
    getDay(date) {
      if (date) {
        const day = Number(date.slice(6, 8))
        return day
      }
      return ''
    }
  },

  props: {
    dateItem: {
      date: { type: String },
      disabledFlg: { type: Boolean },
      selectedFlg: { type: Boolean },
      closedDayFlg: { type: Boolean },
      reservedFlg: { type: Boolean },
      crossFlg: { type: Boolean }
    }
  },

  methods: {
    handleClick() {
      this.$emit('click', this.dateItem.date)
    }
  }
}
</script>

<style lang="scss" scoped>
.calendar-table-date-panel {
  > .calendar-date-panel-button {
    position: relative;
    margin: 3.5px auto;
    padding: 0;
    width: 94%;
    height: 91px;
    border: none;
    background-color: #{$plus_f7_gray};
    font-size: 20px;
    cursor: pointer;
    &:hover {
      opacity: 0.8;
      background-color: #{$plus_orange};
      color: #{$plus_white};
      > .calendar-date-panel-circle-mark {
        color: #{$plus_white};
      }
    }
    &:active {
      margin: 5.5px auto 3.5px auto;
      height: 89px;
    }
    &:disabled {
      pointer-events: none;
    }
    &.selected {
      background-color: #{$plus_orange};
      color: #{$plus_white};
      > .calendar-date-panel-circle-mark {
        color: #{$plus_white};
      }
    }
    &.reserved {
      background-color: #{$plus_7c_gray};
      color: #{$plus_white};
    }
    > .calendar-date-panel-day {
      position: absolute;
      top: 15px;
      left: 0;
      right: 0;
      margin: auto;
      font-weight: bold;
    }
    > .calendar-date-panel-circle-mark {
      position: absolute;
      top: 48px;
      left: 0;
      right: 0;
      color: #{$plus_orange};
      font-weight: bold;
      font-size: 26px;
    }
    > .calendar-date-panel-text {
      position: absolute;
      left: 0;
      right: 0;
      margin: auto;
      word-break: break-all;
      &.reserved {
        top: 51px;
        font-size: 17px;
        line-height: 1;
      }
      &.cross-mark {
        top: 48px;
        font-size: 26px;
        color: #{$plus_be_gray};
      }
      &.closed-day {
        top: 51px;
        font-size: 17px;
        line-height: 1;
        color: #{$plus_be_gray};
      }
    }
  }
}

@media (max-width: 640px) {
  .calendar-table-date-panel {
    > .calendar-date-panel-button {
      margin: 3px auto;
      height: 75px;
      &:active {
        margin: 5px auto 3px auto;
        height: 73px;
      }
      > .calendar-date-panel-day {
        top: 9px;
      }
      > .calendar-date-panel-circle-mark {
        top: 40px;
        font-size: 1.2rem;
      }
      > .calendar-date-panel-text {
        &.reserved {
          top: 43px;
          font-size: 14px;
        }
        &.cross-mark {
          top: 40px;
          font-size: 1.2rem;
        }
        &.closed-day {
          top: 43px;
          font-size: 14px;
        }
      }
    }
  }
}

@media (max-width: 450px) {
  .calendar-table-date-panel {
    > .calendar-date-panel-button {
      > .calendar-date-panel-text {
        &.reserved {
          font-size: 13px;
        }
        &.closed-day {
          font-size: 13px;
        }
      }
    }
  }
}
</style>
