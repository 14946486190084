<template>
  <div class="calendar-table">
    <div class="calendar-title">
      <button
        class="calendar-title-button"
        :class="{ english: englishFlg }"
        v-if="PrevMonthBtn"
        @click="movePreMonth"
        data-test="prev-month-button"
      >
        <span class="calendar-title-button-arrow left" />
        <span
          class="calendar-title-button-text prev"
          :class="{ english: englishFlg }"
          data-test="prev-month-button-text"
          >{{ $t('parts.prevMonth') }}</span
        >
      </button>
      <div
        class="calendar-title-button-dummy"
        :class="{ english: englishFlg }"
        v-else-if="!PrevMonthBtn"
        data-test="prev-calendar-title-button-dummy"
      />
      <div class="calendar-title-date" data-test="year-month">
        {{ displayYearMonth }}
      </div>
      <button
        class="calendar-title-button"
        :class="{ english: englishFlg }"
        v-if="nextMonthBtn"
        @click="moveNextMonth"
        data-test="next-month-button"
        data-e2e="next-month-button"
      >
        <span
          class="calendar-title-button-text next"
          :class="{ english: englishFlg }"
          data-test="next-month-button-text"
          >{{ $t('parts.nextMonth') }}</span
        >
        <span class="calendar-title-button-arrow right" />
      </button>
      <div
        class="calendar-title-button-dummy"
        :class="{ english: englishFlg }"
        v-else-if="!nextMonthBtn"
        data-test="next-calendar-title-button-dummy"
      />
    </div>
    <div class="calendar-body">
      <div class="calendar-body-area-week">
        <div
          class="calendar-body-area-day-week"
          v-for="week in weeks"
          :key="week"
        >
          <div class="calendar-body-area-day-week-text">{{ $t(week) }}</div>
        </div>
      </div>
      <div class="calendar-body-area-days">
        <div
          class="calendar-body-area-day"
          v-for="day in calendarMake"
          :key="day.id"
          :value="day"
        >
          <calendar-table-date-panel :date-item="day" @click="handleClick" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CalendarTableDatePanel from '@/components/parts/atoms/CalendarTableDatePanel.vue'

export default {
  name: 'CalendarTable',

  components: {
    CalendarTableDatePanel
  },

  props: {
    reservationAvailableDates: { type: Array },
    reservationDisplayDates: { type: Array },
    closedDays: { type: Array },
    changeReservation: { type: Object },
    ownerAnotherReservations: { type: Array },
    currentYear: { type: Number },
    currentMonth: { type: Number }
  },

  data() {
    return {
      weeks: [
        'parts.shortSunday',
        'parts.shortMonday',
        'parts.shortTuesday',
        'parts.shortWednesday',
        'parts.shortThursday',
        'parts.shortFriday',
        'parts.shortSaturday'
      ]
    }
  },

  computed: {
    englishFlg() {
      return this.$i18n.locale === 'en'
    },
    displayYearMonth() {
      const month = ('0' + String(this.currentMonth)).slice(-2)
      return this.englishFlg
        ? month + '/' + this.currentYear
        : this.currentYear + '年 ' + month + '月'
    },
    PrevMonthBtn() {
      const date = new Date()
      if (date.getFullYear() > this.currentYear) {
        return false
      }
      if (
        date.getFullYear() === this.currentYear &&
        date.getMonth() + 1 >= this.currentMonth
      ) {
        return false
      } else {
        return true
      }
    },
    nextMonthBtn() {
      const date = this.reservationDisplayDates.slice(-1)[0]
      if (date) {
        const getYear = Number(date.slice(0, 4))
        const getMonth = Number(date.slice(4, 6))
        if (
          this.currentYear > getYear ||
          (this.currentYear === getYear && this.currentMonth >= getMonth)
        ) {
          return false
        } else {
          return true
        }
      }
      return false
    },
    calendarMake() {
      // 「1」のパネルより前に必要なパネル（テキスト無しの灰色のパネル）の数を取得する
      const firstDayWeek = new Date(
        this.currentYear,
        this.currentMonth - 1,
        1
      ).getDay()
      const necessarySpace = firstDayWeek
      // 末日のパネルより後に必要なパネル（テキスト無しの灰色のパネル）の数を取得する
      const lastDayWeek = new Date(
        this.currentYear,
        this.currentMonth,
        0
      ).getDay()
      const lastNecessarySpace = 6 - lastDayWeek
      // カレンダーの日付パネルに使用するデータを配列にしてまとめる
      const listArrays = [
        [...Array(necessarySpace)].map(i => {
          return { date: '', disabledFlg: true }
        }),
        this.dayArray,
        [...Array(lastNecessarySpace)].map(i => {
          return { date: '', disabledFlg: true }
        })
      ]
      // 各配列のデータを連結して、１つの配列データにする
      const list = listArrays.reduce((pre, current) => {
        pre.push(...current)
        return pre
      }, [])
      return list
    },
    dayArray() {
      // 日付を入れる配列の作成
      const lastDay = new Date(this.currentYear, this.currentMonth, 0).getDate()
      const array = [...Array(lastDay)]
      const dayArray = []
      for (let i = 0; i < array.length; i++) {
        const day = new Date(this.currentYear, this.currentMonth - 1, i + 1)
        const getYear = day.getFullYear()
        const getMonth = ('0' + (day.getMonth() + 1)).slice(-2)
        const getDate = ('0' + day.getDate()).slice(-2)
        const reservationDate = `${getYear}${getMonth}${getDate}`
        //同じ飼主(ユーザー)の別の予約データ
        const anotherReservationDate = []
        this.ownerAnotherReservations.map(data => {
          anotherReservationDate.push(data.date)
        })
        // 予約可能日であるかどうか
        if (this.reservationAvailableDates.indexOf(reservationDate) != -1) {
          //前回予約時に選んでいた日付
          if (this.changeReservation.date === reservationDate) {
            dayArray.push({ date: reservationDate, selectedFlg: true })
          } else if (anotherReservationDate.indexOf(reservationDate) != -1) {
            //同じ飼主(ユーザー)の別の予約データで選んだ日付
            dayArray.push({ date: reservationDate, reservedFlg: true })
          } else {
            // その他で予約可能日である日付
            dayArray.push({ date: reservationDate })
          }
        } else if (this.closedDays.indexOf(reservationDate) != -1) {
          // 休日である日付
          dayArray.push({
            date: reservationDate,
            disabledFlg: true,
            closedDayFlg: true
          })
        } else {
          // 予約できない日付
          dayArray.push({
            date: reservationDate,
            disabledFlg: true,
            crossFlg: true
          })
        }
      }
      return dayArray
    }
  },

  methods: {
    handleClick(selectDate) {
      this.$emit('day-click', selectDate)
    },
    movePreMonth() {
      this.$emit('move-pre-month')
    },
    moveNextMonth() {
      this.$emit('move-next-month')
    }
  }
}
</script>

<style lang="scss" scoped>
.calendar-table {
  width: 996px;
  margin: 0 auto;
  > .calendar-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 2px 18px 2px;
    > .calendar-title-date {
      font-size: 20px;
      font-weight: bold;
    }
    > .calendar-title-button {
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 113px;
      height: 39px;
      border-radius: 6px;
      border: solid 2px #{$plus_cc_gray};
      background-color: #{$plus_white};
      padding: 0;
      cursor: pointer;
      @include font-size(18);
      &.english {
        min-width: 160px;
      }
      > .calendar-title-button-text {
        margin-bottom: 2px;
        &.prev {
          margin-left: 19px;
          &.english {
            margin-left: 5px;
          }
        }
        &.next {
          margin-right: 19px;
          &.english {
            margin-right: 5px;
          }
        }
      }
      > .calendar-title-button-arrow {
        content: '';
        box-sizing: border-box;
        height: 8px;
        width: 8px;
        top: 0px;
        left: 0px;
        position: relative;
        display: inline-block;
        border-top: solid 2px #{$plus_orange};
        border-right: solid 2px #{$plus_orange};
        &.left {
          -ms-transform: rotate(-135deg);
          -webkit-transform: rotate(-135deg);
          transform: rotate(-135deg);
        }
        &.right {
          -ms-transform: rotate(45deg);
          -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
        }
      }
    }
    > .calendar-title-button-dummy {
      min-width: 113px;
      height: 37px;
      &.english {
        min-width: 160px;
      }
    }
  }
  > .calendar-body {
    .calendar-body-area-week {
      @include clearfix;
      margin-bottom: 12.5px;
      .calendar-body-area-day-week {
        float: left;
        width: 14.28%;
        height: 37px;
        > .calendar-body-area-day-week-text {
          width: 94%;
          height: 37px;
          margin: 0 auto;
          border: solid 1px #{$plus_cc_gray};
          box-sizing: border-box;
          line-height: 35px;
          @include font-size(18);
        }
      }
    }
    > .calendar-body-area-days {
      @include clearfix;
      > .calendar-body-area-day {
        float: left;
        width: 14.28%;
      }
    }
  }
}
$calendar-width: 617px;
@media (max-width: 960px) {
  .calendar-table {
    max-width: $calendar-width;
    width: calc-percent($calendar-width, 640px);
    > .calendar-body {
      > .calendar-body-area-week {
        margin-bottom: 8px;
      }
    }
  }
}
@media (max-width: 450px) {
  .calendar-table {
    max-width: $calendar-width;
    width: calc-percent($calendar-width, 640px);
    min-width: 320px;
    > .calendar-title {
      > .calendar-title-button {
        min-width: 90px;
        white-space: pre-line;
        &.english {
          min-width: 90px;
        }
        > .calendar-title-button-text {
          &.prev {
            margin-left: 5px;
          }
          &.next {
            margin-right: 5px;
          }
        }
      }
      > .calendar-title-button-dummy {
        min-width: 90px;
        &.english {
          min-width: 90px;
        }
      }
    }
    > .calendar-body {
      > .calendar-body-area-week {
        margin-bottom: 8px;
      }
    }
  }
}
</style>
