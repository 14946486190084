<template>
  <div class="reservation-date-select">
    <base-loading :waitFlg="waitFlg" />
    <div class="page-title">
      <base-page-title>{{
        $t('reservationDateSelect.pageTitle')
      }}</base-page-title>
    </div>
    <div class="reservation-progress-bar">
      <progress-bar v-bind="progressBarData" />
    </div>
    <div class="reservation-calendar-table">
      <calendar-table
        v-bind="calendarTableData"
        :current-year="currentYear"
        :current-month="currentMonth"
        @day-click="pushToReservationTimeSelect"
        @move-pre-month="movePreMonth"
        @move-next-month="moveNextMonth"
      />
    </div>
    <div class="cancel-button">
      <base-cancel-button @click="backPage">{{
        $t('common.buttonReturn')
      }}</base-cancel-button>
    </div>
    <announce-popup
      v-if="alertFlg"
      :type="'failure'"
      :title="$t('common.error')"
      :buttons="buttons"
      @close="alertFlg = false"
      >{{ popupMessage }}</announce-popup
    >
  </div>
</template>

<script>
import ProgressBar from '@/components/parts/organisms/ProgressBar.vue'
import BasePageTitle from '@/components/parts/atoms/BasePageTitle.vue'
import BaseLoading from '@/components/parts/atoms/BaseLoading.vue'
import CalendarTable from '@/components/parts/molecules/CalendarTable.vue'
import BaseCancelButton from '@/components/parts/atoms/BaseCancelButton.vue'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import { getAxiosObject } from '@/utils/library'
import ReloadConfirm from '@/components/mixins/ReloadConfirm'
import { createProgressBarDataForReservation } from '@/utils/createProgressBarData'

export default {
  name: 'ReservationDateSelect',

  components: {
    BasePageTitle,
    BaseLoading,
    BaseCancelButton,
    CalendarTable,
    ProgressBar,
    AnnouncePopup
  },

  mixins: [ReloadConfirm],

  data() {
    return {
      calendarTableData: {
        //↓予約可能な日付データ
        reservationAvailableDates: [],
        //↓表示する日付データ
        reservationDisplayDates: [],
        //↓休日データ（予約できない日付（×の表示）の中で、下記の休日データに該当する日付は「休」で表示する）
        closedDays: [],
        //↓予約編集時に使用するデータ 編集したい前回の予約データ
        changeReservation: {},
        //↓同じユーザー（飼主）の別の予約データ
        ownerAnotherReservations: []
      },
      alertFlg: false,
      popupMessage: '',
      buttons: [],
      progressBarData: {},
      waitFlg: false,
      currentYear: 0,
      currentMonth: 0
    }
  },

  computed: {
    scenario() {
      return this.$store.getters['auth/scenario']
    },
    reservationIds() {
      return this.scenario === 'change_reservation'
        ? this.$store.getters['reservation/getChangeReservation'].ids
        : []
    },
    reservationPurposeId() {
      return this.scenario === 'change_reservation'
        ? this.$store.getters['reservation/getChangeReservation']
            .reservationPurposeId
        : this.$store.getters['reservation/getNewReservation']
            .reservationPurposeId
    }
  },

  created() {
    this.progressBarData = createProgressBarDataForReservation(this.scenario, 2)
    this.getReservationAvailableDates()
    if (
      this.scenario === 'new_reservation' ||
      this.scenario === 'change_reservation'
    ) {
      this.calendarTableData.ownerAnotherReservations = this.$store.getters[
        'reservation/getReservations'
      ]
    }
    if (this.scenario === 'change_reservation') {
      this.calendarTableData.changeReservation = this.$store.getters[
        'reservation/getChangeReservation'
      ]
    }
    this.$store.dispatch('reservation/resetDate')
  },

  methods: {
    async getReservationAvailableDates() {
      try {
        this.waitFlg = true
        const axiosObject = getAxiosObject()
        const response = await axiosObject.post('/available-dates', {
          reservationIds: this.reservationIds,
          reservationPurposeId: this.reservationPurposeId
        })
        const resReservationDisplayDates =
          response.data.reservationAvailableDates
        this.calendarTableData.reservationDisplayDates = resReservationDisplayDates.map(
          v => v.receptionDate
        )
        this.calendarTableData.reservationAvailableDates = resReservationDisplayDates
          .filter(v => v.available)
          .map(v => v.receptionDate)
        this.calendarTableData.closedDays = response.data.closedDays
        if (this.calendarTableData.reservationAvailableDates.length > 0) {
          this.currentYear = Number(
            this.calendarTableData.reservationAvailableDates[0].substring(0, 4)
          )
          this.currentMonth = Number(
            this.calendarTableData.reservationAvailableDates[0].substring(4, 6)
          )
        } else {
          const date = new Date()
          this.currentYear = date.getFullYear()
          this.currentMonth = date.getMonth() + 1
        }
        this.waitFlg = false
      } catch (ex) {
        if (ex.response?.status === 401) {
          this.buttons = [this.$t('common.buttonGoToLogin')]
        } else {
          this.popupMessage = this.$t(
            'common.popupMessageServerErrorReloadPage'
          )
          this.buttons = [this.$t('common.close')]
        }
        this.alertFlg = true
        this.waitFlg = false
      }
    },
    pushToReservationTimeSelect(selectDate) {
      this.scenario === 'change_reservation'
        ? this.$store.dispatch(
            'reservation/setChangeReservationDate',
            selectDate
          )
        : this.$store.dispatch('reservation/setNewReservationDate', selectDate)
      this.$router.push({ name: 'ReservationTimeSelect' })
    },
    backPage() {
      this.$router.back()
    },
    movePreMonth() {
      this.currentMonth = this.currentMonth != 1 ? this.currentMonth - 1 : 12
      this.currentYear =
        this.currentMonth != 12 ? this.currentYear : this.currentYear - 1
    },
    moveNextMonth() {
      this.currentMonth = this.currentMonth != 12 ? this.currentMonth + 1 : 1
      this.currentYear =
        this.currentMonth != 1 ? this.currentYear : this.currentYear + 1
    }
  }
}
</script>

<style lang="scss" scoped>
.reservation-date-select {
  margin-bottom: 100px;
  > .page-title {
    margin-top: 70px;
  }
  > .reservation-progress-bar {
    margin-top: 28px;
  }
  > .reservation-calendar-table {
    margin-top: 115px;
  }
  > .cancel-button {
    margin-top: 85px;
  }
}
@media (max-width: $global-container) {
  .reservation-date-select {
    > .page-title {
      margin-top: 63px;
    }
    > .reservation-progress-bar {
      margin-top: 24px;
    }
    > .reservation-calendar-table {
      margin-top: 80px;
    }
    > .cancel-button {
      margin-top: 81px;
    }
  }
}
@media (max-width: 450px) {
  .reservation-date-select {
    min-width: 320px;
    > .page-title {
      margin-top: 20px;
    }
    > .reservation-progress-bar {
      margin-top: 20px;
    }
    > .reservation-calendar-table {
      margin-top: 40px;
    }
    > .cancel-button {
      margin-top: 40px;
    }
  }
}
</style>
